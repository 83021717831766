.inline-block {
  display: inline-block;
}

.prompt-active {
  background-color: lightgray !important;
}

.function-bubble .cs-message__content {
  background-color: beige !important;
}